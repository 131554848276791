export default {
    data() {
        return {
            emptyHeight: 0
        }
    },
    created() {
        const e = document.getElementById('cover');
        e.style.background = 'none';
    },
    methods: {
        setCoverImage(imageUrl) {
            const e = document.getElementById('cover');
            e.style.backgroundImage = `url(${imageUrl})`;
            e.style.backgroundSize = '100%';
            e.style.backgroundRepeat = 'no-repeat'
            e.style.backgroundAttachment = 'fixed';
            e.style.animation = 'fadeIn 3s ease-in-out';
        },
        addCoverResize() {
            const proportion = 4952 / 3302;
            const menuHeight = 125;
    
            this.emptyHeight = window.innerWidth / proportion - menuHeight;
    
            window.addEventListener('resize', (e) => {
                this.emptyHeight = e.target.innerWidth / proportion - menuHeight;
            });
        },
        removeCoverResize() {
            document.removeEventListener('resize', () => {});
        }
    }
}