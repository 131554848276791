<template>
    <div id="main">
        <!-- empty -->
        <div class="empty" :style="{ 'height': `${emptyHeight}px` }"></div>
        <!-- collection -->
        <Row id="collection" class="collection" type="flex" justify="center" align="middle">
            <h3>C O L L E C T I O N</h3>
            <Col :span="22" :md="18" :lg="16">
                <Row class="grid" :gutter="10">
                    <Col :span="16">
                        <div class="item" @click="itemClick('/Mutation')">
                            <img src="@img/collection/8select-1.jpg">
                            <div class="mask"></div>
                            <div class="des">M U T A T I O N</div>
                        </div>
                        <div style="display: flex;">
                            <div style="margin-right: 10px;">
                                <div class="item" @click="itemClick('/Textures')">
                                    <img src="@img/collection/8select-6.jpg">
                                    <div class="mask"></div>
                                    <div class="des">T E X T U R E S / T E X T I L E S</div>
                                </div>
                                <div class="item" @click="itemClick('/Fantasy')">
                                    <img src="@img/collection/8select-7.jpg">
                                    <div class="mask"></div>
                                    <div class="des">F A N T S A Y</div>
                                </div>
                            </div>
                            <div>
                                <div class="item" @click="itemClick('/Illustration')">
                                    <img src="@img/collection/8select-2.jpg">
                                    <div class="mask"></div>
                                    <div class="des">I L L U S T R A T I O N</div>
                                </div>
                                <div class="item" @click="itemClick('/Women')">
                                    <img src="@img/collection/8select-3.jpg">
                                    <div class="mask"></div>
                                    <div class="des">W O M E N S W E A R</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col :span="8">
                        <div class="item" @click="itemClick('/YoungLady')">
                            <img src="@img/collection/8select-4.jpg">   
                            <div class="mask"></div>
                            <div class="des"><pre>Y O U N G  L A D Y</pre></div>             
                        </div>
                        <div class="item" @click="itemClick('/Paper')">
                            <img src="@img/collection/8select-5.jpg">     
                            <div class="mask"></div>
                            <div class="des">P A P E R</div>
                        </div>
                        <div class="item" @click="itemClick('/Sustainable')">
                            <img src="@img/collection/8select-8.jpg">  
                            <div class="mask"></div>
                            <div class="des">S U S T A I N A B L E</div>                              
                        </div>
                    </Col>
                </Row>

                <!-- old -->
                <ul v-if="false" class="waterfall"> 
                    <li class="item">
                        <img src="@img/collection/8select-1.jpg">
                        <div class="mask"></div>
                        <div class="des">MUTATION</div>
                    </li> 
                    <li class="item">
                        <img src="@img/collection/8select-2.jpg">
                        <div class="mask"></div>
                        <div class="des">ILLUSTRATION</div>
                    </li> 
                    <li class="item">
                        <img src="@img/collection/8select-3.jpg">
                        <div class="mask"></div>
                        <div class="des">WOMENSWEAR</div>
                    </li> 
                    <li class="item">
                        <img src="@img/collection/8select-4.jpg">
                        <div class="mask"></div>
                        <div class="des">YOUNG LADY</div>
                    </li> 
                    <li class="item">
                        <img src="@img/collection/8select-5.jpg">
                        <div class="mask"></div>
                        <div class="des">PAPER</div>
                    </li> 
                    <li class="item">
                        <img src="@img/collection/8select-6.jpg">
                        <div class="mask"></div>
                        <div class="des">TEXTURES AND TEXTILES</div>
                    </li> 
                    <li class="item">
                        <img src="@img/collection/8select-7.jpg">
                        <div class="mask"></div>
                        <div class="des">FANTSAY</div>
                    </li> 
                    <li class="item">
                        <img src="@img/collection/8select-8.jpg">
                        <div class="mask"></div>
                        <div class="des">SUSTAINABLE</div>
                    </li> 
                </ul>
            </Col>
        </Row>
        <!-- story -->
        <Row id="story" class="story" type="flex" justify="center" align="middle">
            <h3>S T O R Y</h3>
            <Col :span="20" :md="18" :lg="16" class="content">
                Wen-Ching Yen was born in Taiwan. She holds a Fashion Design MA of Kingston University,
            </Col>
            <Col :span="20" :md="18" :lg="16" class="content">
                and have been extensive experience of fashion design for roughly seven years.
            </Col>
            <Col :span="20" :md="18" :lg="16" class="content">
                Here, you can explore variety of collections her designed and created.
            </Col>
            <Col :span="20" :md="18" :lg="16" class="note">
                fashion is the armor to survive the reality of everyday life. —bill cunningham
            </Col>
        </Row>
        <!-- banner -->
        <div class="banner" >
            <div v-for="item in bannerList" :key="item.imageIndex">
                <img :src="require(`@img/banner/banner-${item.imageIndex}.jpg`)" alt="banner">
                <div class="link" @click="$router.push({ path: item.link })">{{item.label}}</div>
            </div>
        </div>
        <!-- contact -->
        <Row id="contact" class="contact" type="flex" justify="center" align="middle">
            <!-- <h3>ＣＯＮＴＡＣＴ</h3> -->
            <Row class="find" type="flex" justify="space-around">
                <Col :xs="24" :sm="8">
                    <div>
                        <h4>Find me here</h4>
                        <!-- <a href="" target="_blank"><Icon type="logo-facebook" /></a> -->
                        <a href="mailto:aalice.yen@gmail.com" target="_blank"><Icon type="logo-google" /></a>
                        <!-- <a href="" target="_blank"><Icon type="logo-twitter" /></a> -->
                        <a href="https://www.linkedin.com/in/wenchingyen/" target="_blank"><Icon type="logo-linkedin" /></a>
                    </div>
                </Col>
                <Col :xs="24" :sm="8">
                    <div>
                        <h4>Contact with me</h4>
                        <div class="contact-text">+44 7746263709</div>
                        <div class="contact-text">aalice.yen@gmail.com</div>
                    </div>
                </Col>
                <Col :xs="24" :sm="8">
                    <div>
                        <h4>Localization</h4>
                        <div class="contact-text">London, United Kingdom</div>
                    </div>
                </Col>
            </Row>
            <copy-right />
            
        </Row>
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
    name: "Home",
    mixins: [common],
    components: {
        CopyRight
    },
    data() {
        return {}
    },
    created() {
        this.setCoverImage(require('@img/cover.jpg'));
    },
    computed: {
        bannerList() {
            return [
                { imageIndex: '1', label: 'T E X T U R E S / T E X T I L E S', link: '/Textures' },
                { imageIndex: '2', label: 'Y O U N G  L A D Y', link: '/YoungLady' },
                { imageIndex: '3', label: 'P A P E R', link: '/Paper' },
                { imageIndex: '4', label: 'F A I R Y   T A L E S', link: '/Fantasy' },
                { imageIndex: '5', label: 'I L L U S T R A T I O N', link: '/Illustration' }
            ]
        }
    },
    mounted() {
        this.addCoverResize();
    },
    methods: {
        itemClick(path) {
            this.$router.push({ path })
        }
    },
    beforeDestroy() {
        this.removeCoverResize();
    }
}
</script>

<style lang="scss">
// @font-face {
//   font-family: msReferenceSansSerif;
//   src: url('../assets/font/ms reference sans serif.ttf');
//   font-weight: bold;
// }

// @font-face {
//   font-family: ArialBlack;
//   src: url('../assets/font/Arial-Black.ttf');
//   font-weight: bold;
// }

#app {
    // background: url('~@img/cover.jpg');
    // background-size: 100%;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    font-family: msReferenceSansSerif;
}

.empty {
    height: 50vh;
    // background: yellow; // use debug
    opacity: .2;
}

.collection {
    background: $collection-background;
    flex-direction: column;
    padding-bottom: 6rem;
    h3 {
        margin: 7rem 0 5rem 0;
        font-weight: bold;
        font-size: 20px;
    }
    > div {
        max-width: 800px;
    }
    .grid {
        .item {
            list-style-type: none;
            break-inside: avoid;
            position: relative;
            display: inline-flex;
            cursor: pointer;
            margin-bottom: 10px;

            img {
                width: 100%;
                height: intrinsic;
                vertical-align: bottom;
            }
            .mask {
                position: absolute;
                background: $collection-mask;
                width: 100%;
                height: 100%;
                opacity: .5;
            }
            .des {
                width: 100%;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 0;
                background: rgba(244, 244, 242, 0.9);
                font-size: 12px;
                opacity: 0;
                color: black;
                pre {
                    font-family: 'msReferenceSansSerif';
                }
            }
            &:hover {
                // opacity: 1;
                
                .mask { 
                    animation: maskInOut .3s ease-in-out;
                    animation-fill-mode: forwards;
                    // display: none; 
                }
                .des {
                    animation: desInOut .3s ease-in-out;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
    .waterfall { 
        column-count:3;
        .item {
            list-style-type: none;
            break-inside: avoid;
            position: relative;
            display: inline-flex;
            cursor: pointer;

            img {
                width: 100%;
                height: intrinsic;
            }
            .mask {
                position: absolute;
                background: $collection-mask;
                width: 100%;
                height: 100%;
                opacity: .5;
            }
            .des {
                width: 100%;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 0;
                background: rgba(244, 244, 242, 0.9);
                font-size: 12px;
                opacity: 0;
                color: black;
            }
            &:hover {
                // opacity: 1;
                
                .mask { 
                    animation: maskInOut .3s ease-in-out;
                    animation-fill-mode: forwards;
                    // display: none; 
                }
                .des {
                    animation: desInOut .3s ease-in-out;
                    animation-fill-mode: forwards;
                }
            }
        }
    } 
}

@keyframes maskInOut {
    0% { opacity: .5 }
    100% { opacity: 0 }
}

@keyframes desInOut {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

.story {
    background: $story-background;
    flex-direction: column;
    padding-bottom: 7rem;
    color: #000;

    :nth-child(4) {
        margin-bottom: 3rem;
    }

    h3 {
        margin: 7rem 0 3rem 0;
        color: #242424;
        font-weight: bold;
        font-size: 20px;
    }

    .content {
        line-height: 1.8;
        font-size: 14px;
    }

    .note {
        font-family: Maturasc;
        font-style: oblique;
        text-align: end;
        font-size: 14pt;
    }

    

}

.banner {
    display: flex;
    flex-direction: column;
    > div { 
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;    

        img { width: 100%; }
        .link {
            display: flex;
            width: 330px;
            height: 60px;
            position: absolute;
            background: white;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-family: ArialBlack;
            cursor: pointer;
            &:hover {
                background: #d5e6e9;
            }
        }
    }
}

.contact {
    background: $contact-background;
    flex-direction: column;
    color: white;
    .find {
        width: 100%;
        margin-bottom: 8rem;
        > div > div {
            margin: 0 auto;
            display: table;

            > a {
                margin-right: .5rem;
                cursor: pointer;
                color: #fff;
            }
        }
        h4 {
            margin: 6rem 0 4rem 0;
            font-family: Arial Black;
        }
        .contact-text {
            line-height: 2.5;
        }
    }
}
</style>