<template>
    <div class="copy-right" :class="{ 'mobile': isMobile }">
        <pre>©2020 Wen-Chi n g   Ye  n   Portfolio</pre>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'isMobile'
        ])
    }
}
</script>

<style lang="scss">
.copy-right {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: $contact-background;
    // padding: 8rem 0 1rem 0;

    &.mobile {
        font-size: 14px;
    }
}
</style>